import App from 'next/app';
import ErrorPage from 'next/error';
import { ErrorBoundary } from '@buzzfeed/react-components';
import { captureException } from '@sentry/nextjs';
import i18n from '../i18n';
import '../styles/main.scss';
import '../components/Ads/Ad.scss';
import { LOCAL_API, ROUTED_API } from '../constants';
import { TakeoverContext, TrackingContext, PageContext } from '../contexts';
require.context('../public/static/locales', true, /\.json$/);

const API_URL = process.env.NODE_ENV !== 'development' ? ROUTED_API : LOCAL_API;
class Application extends App {
  async onError(error, errorInfo, pageProps) {
    const { tracking } = pageProps;
    const isHomepage = tracking.context_page_id === 1;
    const isArcadePage = tracking.context_page_id === 6;
    let page = 'other';
    if (isHomepage) {
      page = 'home'
    } else if (tracking.context_page_id === 8) {
      page = 'topic'
    } else if (tracking.context_page_id.startsWith('community_')) {
      page = 'community';
    } else if (isArcadePage) {
      page = 'arcade';
    }
    if (isHomepage || isArcadePage) {
      captureException(error, {
        level: 'fatal',
        extra: errorInfo,
        tags: {
          error_boundary: 'application'
        }
      });
    }
    await fetch(`${API_URL}client-error?page=${page}&area=page`);
  }

  render() {
    const { Component, pageProps, router } = this.props;

    let path = router?.state?.asPath || '';
    try {
      // pull path without query params or hashes
      path = (new URL(`http://_${path}`)).pathname;
    } catch (e) {
      // gracefully allow error
    }

    return (
      <ErrorBoundary
        fallbackRender={() => (
          <ErrorPage
            statusCode={500}
            title="An unexpected error has occurred"
          />
        )}
        onError={(error, errorInfo) => this.onError(error, errorInfo, pageProps)}
      >
        <TrackingContext.Provider value={{ ...pageProps.tracking }}>
          <TakeoverContext.Provider value={pageProps.takeover}>
            <PageContext.Provider value={{
              adsDisabled: !!pageProps.adsDisabled,
              isSponsored: pageProps.tracking.context_page_id === 1 ? !!pageProps.sponsorship?.sponsor?.name : !!pageProps.metadata?.sponsor,
              membershipAvailable: pageProps?.userGeo === 'US' || pageProps?.userGeo === 'CA',
              pageType: pageProps.pageConfig?.pageType,
              path,
            }}>
              <Component {...pageProps} />
            </PageContext.Provider>
          </TakeoverContext.Provider>
        </TrackingContext.Provider>
      </ErrorBoundary>
    );
  }
}

export default i18n.appWithTranslation(Application);
